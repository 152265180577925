export const STATUS_REPORTABLE = 'STATUS_REPORTABLE';
export const STATUS_NOT_REPORTABLE = 'STATUS_NOT_REPORTABLE';
export const STATUS_EXCEPTION = 'STATUS_EXCEPTION';
export const STATUS_INCLUDED_IN_REPORT = 'STATUS_INCLUDED_IN_REPORT';
export const STATUS_REPORTED_OVERRIDE = 'STATUS_REPORTED_OVERRIDE';
export const STATUS_REPORTED = 'STATUS_REPORTED';
export const STATUS_MISSING_FILES = 'STATUS_MISSING_FILES';
export const STATUS_IGNORED = 'STATUS_IGNORED';
export const STATUS_INVALID = 'STATUS_INVALID';
export const STATUS_ERROR = 'STATUS_ERROR';
