
























































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { I8Alert, I8Form } from 'i8-ui';
import { PrBackButton } from '@/component/pr-back-button';
import { PrDecision } from '@/component/pr-decision';
import { PrDiff } from '@/component/pr-diff';
import { ReportItemEditRequest } from '@/store';
import { PrPage } from '@/mixin';

export default Vue.extend({
  mixins: [PrPage],

  components: {
    I8Alert,
    I8Form,
    PrDiff,
    PrBackButton,
    PrDecision,
  },

  props: {
    oldVal: {
      type: [Object, String, Array],
      required: true,
    },
    newVal: {
      type: [Object, String, Array],
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    exceptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formValidation: {},
      formModel: { justification: '' },
      formSchema: {
        elements: {
          justification: {
            type: 'textarea',
            label: 'Comment',
            required: true,
          },
        },
      },
      hasError: false,
      string: {
        title: 'Report Item Modification',
        error:
          'There may be pending approvals that may affect this exception. Please approve any existing edits and try again.',
      },
    };
  },

  computed: {
    ...mapGetters('reportItem', ['detailsByReportItemId']),

    reportItemId(): string {
      return this.$route.params.id;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.detailsByReportItemId(this.reportItemId);
    },
  },

  methods: {
    ...mapActions('reportItem', ['reportItemEditRequest']),

    async editCreate(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      const request: ReportItemEditRequest = {
        reportItemId: this.reportItemId,
        edit: {
          path: this.path,
          json_value: JSON.stringify(this.newVal),
          justification: this.formModel.justification,
        },
      };
      try {
        await vm.reportItemEditRequest(request);
        this.$router.push({
          name: 'identitii.payreg.reportitem.v1.ReportItem',
          params: { reportItemId: vm.reportItemId },
        });
      } catch {
        vm.hasError = true;
      }
    },

    cancel(): void {
      this.$router.back();
    },
  },
});
