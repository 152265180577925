

























































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { I8Form } from 'i8-ui';
import { PrBackButton, PrException } from '@/component';
import { ExceptionIgnoreRequest } from '@/store';
import { PrPage } from '@/mixin';

export default Vue.extend({
  mixins: [PrPage],

  components: {
    I8Form,
    PrBackButton,
    PrException,
  },

  props: {
    exceptionMessage: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      pageTitle: 'Ignore Exception',
      formValidation: {},
      formModel: { justification: '' },
      formSchema: {
        elements: {
          justification: {
            type: 'textarea',
            label: 'Comment',
            required: true,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('reportItem', ['detailsByReportItemId']),

    reportItemId(): string {
      return this.$route.params.id;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.detailsByReportItemId(this.reportItemId);
    },

    exceptionId(): string {
      return this.$route.params.exceptionId;
    },

    // When we ignore reportable-with-exception, we actually want to display it as 'Confirm reportable' rather than 'Ignore'
    isConfirmReportable(): boolean {
      return this.exceptionId === 'reportable-with-exception';
    },

    title(): string {
      if (this.isConfirmReportable) {
        return 'Transaction reportable';
      }

      return this.pageTitle;
    },
  },

  methods: {
    ...mapActions('reportItem', ['exceptionIgnoreRequest']),

    async ignoreException(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      const payload: ExceptionIgnoreRequest = {
        reportItemId: this.reportItemId,
        exceptionId: this.exceptionId,
        justification: this.formModel.justification,
      };

      await vm.exceptionIgnoreRequest(payload);

      this.$router.push({
        name: 'identitii.payreg.reportitem.v1.ReportItem',
        params: { reportItemId: vm.reportItemId },
      });
    },

    cancel(): void {
      this.$router.back();
    },
  },
});
