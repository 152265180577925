












































































































// vue
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { OPluginContext } from 'overlay-plugin-sdk';

import { PrPage } from '@/mixin';
import { Decision } from '@/service';
import {
  PrPageHeader,
  PrEditorList,
  PrValidationExceptionList,
  PrExceptionList,
  PrDecisionList,
  PrMissingFileList,
  DECISION_NOT_REPORTABLE,
} from '@/component';
import { PrReportItemEmpty } from '@/component/pr-report-item-empty/pr-report-item-empty.vue';

import { STATUS_EXCEPTION, STATUS_MISSING_FILES } from './consts';

import { pluralise } from 'i8-ui';

export default Vue.extend({
  mixins: [PrPage],

  components: {
    PrPageHeader,
    PrEditorList,
    PrValidationExceptionList,
    PrExceptionList,
    PrDecisionList,
    PrReportItemEmpty,
    PrMissingFileList,
  },

  data() {
    return {
      string: {
        notReportable: {
          btnTxt: 'Transaction not reportable',
          helper: 'You will need to provide supporting documentation.',
        },
      },
      ignoreableStatusList: [STATUS_EXCEPTION, STATUS_MISSING_FILES],
      showAll: false,
    };
  },

  computed: {
    ...mapGetters('reportItem', [
      'exceptionsByReportItemId',
      'validationErrorsByReportItemId',
      'editorsByReportItemId',
      'decisionsByReportItemId',
      'decisionsElevatedByReportItemId',
      'statusByReportItemId',
      'missingFilesByReportItemId',
      'readOnlyByReportItemId',
      'detailsByReportItemId',
    ]),
    ...mapGetters('overlay', ['pluginContext']),

    reportItemId(): string {
      return this.$route.params.id;
    },

    hasData(): boolean {
      return (
        this.decisions.length > 0 ||
        this.decisionsElevated.length > 0 ||
        this.editors.length > 0 ||
        this.exceptions.length > 0 ||
        this.schemaErrors.length > 0 ||
        this.missingFiles.length > 0
      );
    },

    hasElevatedDecisions(): boolean {
      return this.decisionsElevated.length > 0;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    decisions(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.decisionsByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    decisionsElevated(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.decisionsElevatedByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exceptions(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.exceptionsByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    editors(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.editorsByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    missingFiles(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.missingFilesByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schemaErrors(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.validationErrorsByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    status(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.statusByReportItemId(this.reportItemId);
    },

    isReadOnly(): boolean {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.readOnlyByReportItemId(this.reportItemId);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.detailsByReportItemId(this.reportItemId);
    },

    canMarkNotReportable(): boolean {
      if (this.isReadOnly) {
        // this report item has been explicitly marked as read only
        // meaning the user cannot make any changes.
        return false;
      }

      // A transaction can only be marked as reportable in certain statuses
      if (!this.ignoreableStatusList.includes(this.status)) {
        return false;
      }

      const hasExitingRequest =
        this.decisions.filter(
          (d: Decision) => d.type === DECISION_NOT_REPORTABLE,
        ).length > 0;

      // A new request cannot be made if we already have one
      if (hasExitingRequest) {
        return false;
      }

      return true;
    },
  },

  methods: {
    ...mapActions('reportItem', ['reportItemGet']),

    async loadData(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      if (!this.reportItemId) {
        return;
      }

      vm.stateLoading();
      try {
        await vm.reportItemGet(this.reportItemId);
        vm.stateSuccess();
      } catch (e) {
        vm.stateError(e);
      }
    },

    notReportable(): void {
      this.$router.push({
        name: 'report-item.not-reportable',
        params: { reportItemId: this.reportItemId },
      });
    },

    pluralise: pluralise,
  },

  watch: {
    reportItemId: {
      /**
       * Load page data when the reportItem we're looking at changes.
       *
       * This also loads data on a fresh page load.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      handler(newVal: any) {
        this.loadData();
      },
      immediate: true,
    },

    pluginContext: {
      handler(ctx: OPluginContext, oldCtx: OPluginContext) {
        if (ctx.type !== 'document' || !oldCtx || oldCtx.type !== 'document') {
          // When the new context is not a document, app.vue will route us
          // to the appropriate page and we should do nothing here.
          //
          // An empty old context or one that isn't a document is likely to be a
          // fresh page load. Loading data for new page loads is done in the
          // `reportItemId` watch handler.
          return;
        }

        if (ctx.meta.id !== this.reportItemId) {
          // The new context is for a different document.
          // When this happens, app.vue should either route the user to the
          // appropriate page or update the query string to the correct doc.
          // if the query string is updated, the `reportItemId` watch handler
          // will pickup the change.
          return;
        }

        if (ctx.meta.version <= oldCtx.meta.version) {
          // We don't want to load a fresh data set unless we have stale data
          return;
        }

        // if Overlay+ has sent us a new version of the same document,
        // we need to load a fresh dataset to stay in sync with the main window.
        this.loadData();
      },
      immediate: true,
      deep: true,
    },
  },
});
