
























































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrBackButton, PrUploading, PrUploaded } from '@/component';
import { PrPage } from '@/mixin';

import { I8Form } from 'i8-ui';

// Icon library
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faExclamationCircle);

export default Vue.extend({
  mixins: [PrPage],

  components: {
    I8Form,
    PrBackButton,
    PrUploading,
    PrUploaded,
  },

  data() {
    return {
      formValidation: { isValid: false },
      formModel: { file: {}, justification: '' },
      uploadPercentage: 0,
      formSchema: {
        elements: {
          file: {
            type: 'file',
            label: 'Supporting file from (.pdf)',
            required: true,
            accept: '.pdf',
          },
          justification: {
            type: 'textarea',
            label: 'Comment',
            ref: 'justification',
            required: true,
          },
        },
      },
      string: {
        title: 'transaction not reportable',
      },
    };
  },

  computed: {
    ...mapGetters('reportItem', ['detailsByReportItemId']),

    reportItemId(): string {
      return this.$route.params.id;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details(): any {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return vm.detailsByReportItemId(this.reportItemId);
    },
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const vm = this as any;
    // we aren't loading any data so we can go straight to success
    vm.stateSuccess();
  },

  methods: {
    ...mapActions('reportItem', ['notReportableRequest']),

    async submit(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      if (this.formModel.justification.trim() === '') {
        console.log('justificiation empty');
      }

      if (!this.formModel.file) {
        console.log('file empty');
      }

      vm.stateUploading();

      try {
        await vm.notReportableRequest({
          reportItemId: this.reportItemId,
          file: this.formModel.file,
          justification: this.formModel.justification,
          onUploadProgress: (p: ProgressEvent) => {
            this.uploadPercentage = Math.round((p.loaded / p.total) * 100);
          },
        });
      } catch (e) {
        vm.stateError(e);
        return;
      }

      // successful upload
      vm.stateUploaded();

      // hide the success message and to to the main
      // report item view
      setTimeout(() => {
        this.$router.push({
          name: 'identitii.payreg.reportitem.v1.ReportItem',
          params: { reportItemId: vm.reportItemId },
        });
      }, 2000);
    },

    cancel(): void {
      this.$router.back();
    },
  },
});
