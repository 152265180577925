
















































import Vue from 'vue';
import { mapActions } from 'vuex';
import { PrDecision } from '@/component';
import { PrPage } from '@/mixin';

import { I8Form } from 'i8-ui';
import {
  PrBackButton,
  DecisionEdit,
  DecisionOriginalEdit,
  DecisionExceptionIgnore,
  DecisionNotReportable,
} from '@/component';
import {
  ReportItemEditReject,
  ExceptionIgnoreReject,
  NotReportableReject,
} from '@/store';
import {
  DECISION_EDIT,
  DECISION_ORIGINAL_EDIT,
  DECISION_EXCEPTION_IGNORE,
  DECISION_NOT_REPORTABLE,
} from '@/component/pr-decision';

export default Vue.extend({
  mixins: [PrPage],

  components: {
    I8Form,
    PrBackButton,
    PrDecision,
    DecisionEdit,
    DecisionOriginalEdit,
    DecisionExceptionIgnore,
    DecisionNotReportable,
  },

  props: {
    decision: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pageTitle: 'Reject',
      formValidation: {},
      formModel: { reason: '' },
      formSchema: {
        elements: {
          reason: {
            type: 'textarea',
            label: 'Reason',
            required: true,
          },
        },
      },
      string: {
        title: 'Reject Modification',
      },
    };
  },

  computed: {
    reportItemId(): string {
      return this.$route.params.id;
    },
  },

  methods: {
    ...mapActions('reportItem', [
      'reportItemEditReject',
      'reportEntryEditReject',
      'exceptionIgnoreReject',
      'notReportableReject',
    ]),

    async reject(): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;

      switch (this.decision.type) {
        case DECISION_EDIT: {
          const editReject: ReportItemEditReject = {
            reportItemId: this.reportItemId,
            editId: this.decision.id,
            reason: this.formModel.reason,
          };
          await vm.reportItemEditReject(editReject);
          break;
        }
        case DECISION_ORIGINAL_EDIT: {
          const originalEditReject: ReportItemEditReject = {
            reportItemId: this.reportItemId,
            editId: this.decision.id,
            reason: this.formModel.reason,
          };
          await vm.reportEntryEditReject(originalEditReject);
          break;
        }
        case DECISION_EXCEPTION_IGNORE: {
          const ignoreReject: ExceptionIgnoreReject = {
            reportItemId: this.reportItemId,
            exceptionId: this.decision[DECISION_EXCEPTION_IGNORE].exception.id,
            reason: this.formModel.reason,
          };
          await vm.exceptionIgnoreReject(ignoreReject);
          break;
        }
        case DECISION_NOT_REPORTABLE: {
          const notReportableReject: NotReportableReject = {
            reportItemId: this.reportItemId,
            reason: this.formModel.reason,
          };
          await vm.notReportableReject(notReportableReject);
          break;
        }
        default: {
          console.warn('unknown decision type', this.decision);
          break;
        }
      }

      this.$router.push({
        name: 'identitii.payreg.reportitem.v1.ReportItem',
        params: { reportItemId: vm.reportItemId },
      });
    },

    cancel(): void {
      this.$router.back();
    },
  },
});
